<template>
  <div>
    <el-card
      :body-style="{ padding: '2%' }"
      style="margin: 1.5%"
      shadow="never"
    >
      <div class="aiuda">
        <span style="font-size: 24px; font-weight: 600"
          >Modificar Beneficiario</span
        >
        <el-button class="button" @click="$router.go(-1)"
          >Volver atrás</el-button
        >
      </div>
      <el-divider />

      <el-form
        :model="form"
        ref="form"
        :rules="formRules"
        label-position="top"
        label-width="130px"
        v-loading="cargando"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="Nombre"
              placeholder="Ingrese nombre del beneficiario"
              prop="nombre"
            >
              <el-input v-model="form.nombre"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="Apellido"
              placeholder="Ingrese apellido del beneficiario"
              prop="apellido"
            >
              <el-input v-model="form.apellido"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="DNI" prop="dni">
              <el-input
                v-model="form.dni"
                placeholder="Ingrese número de documento del beneficiario"
                type="number"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="CUIT/CUIL" prop="cuitCuil">
              <el-input
                v-model="form.cuitCuil"
                placeholder="Ingrese CUIT/CUIL del beneficiario"
                type="number"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Razón Social" prop="razonSocial">
              <el-input
                maxlength="20"
                placeholder="Ingrese razón social del beneficiario"
                v-model="form.razonSocial"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="Email" prop="email">
          <el-input
            class="inputEmail"
            maxlength="300"
            v-model="form.email"
          ></el-input>
        </el-form-item>
        <el-form-item label="Fecha de Nacimiento" prop="fechaNacimiento">
          <el-popover
            placement="right"
            visible-arrow="true"
            v-model="visible"
            trigger="hover"
          >
            <span v-if="yaEligioFecha == true"> {{ this.edad }} años</span>
            <span v-else>No seleccionó fecha</span>
            <el-date-picker
              slot="reference"
              v-model="form.fechaNac"
              type="date"
              :picker-options="pickerOptions"
              placeholder="Seleccionar fecha"
              format="dd-MM-yyyy"
              value-format="yyyy-MM-dd"
              @change="calcularEdad()"
            >
            </el-date-picker>
          </el-popover>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="16">
            <el-form-item label="Domicilio" prop="domicilio">
              <el-input
                maxlength="30"
                placeholder="Ingrese domicilio del beneficiario"
                v-model="form.domicilio"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Teléfono" prop="telefono">
              <el-input
                maxlength="15"
                placeholder="Ingrese teléfono del beneficiario"
                v-model="form.telefono"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <!-- VER -->
          <el-col :spon="12">
            <el-form-item label="Departamento del beneficiario">
              <el-input v-model="departamentoBeneficiario" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :spon="12">
            <el-form-item label="Localidad del beneficiario">
              <el-input v-model="localidadBeneficiario" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- VER -->
        <el-form-item label="¿Desea modificar la localidad del beneficiario?">
          <el-switch
            @change="cambio"
            style="display: block; margin-top: 7px"
            v-model="modificarLocalidad"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="SI"
            inactive-text="NO"
          >
          </el-switch>
        </el-form-item>
        <div class="contenedor" v-if="modificarLocalidad">
          <el-form-item label="Departamento" prop="departamento">
            <el-select
              v-model="form.departamentoID"
              class="m-2"
              placeholder="Seleccione un departamento de la lista"
              @change="cambioDepartamento"
            >
              <el-option
                v-for="item in departamentosSelect"
                :key="item.id"
                :label="item.nombre"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            v-loading="cargandoLocalidades"
            label="Localidad"
            prop="localidad"
          >
            <el-select
              :disabled="!form.departamentoID"
              v-model="form.localidadID"
              class="m-2"
              placeholder="Seleccione una localidad de la lista"
              @change="cambioLocalidad"
            >
              <el-option
                v-for="item in localidadesSelect"
                :key="item.id"
                :label="item.nombre"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="contenedor">
          <el-form-item label="Formato de coordenadas" prop="latLongFloat">
            <el-switch
              style="display: block; margin-top: 7px"
              v-model="valueFormatoDecimales"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="Decimales"
              inactive-text="Grados, minutos y segundos"
            >
            </el-switch>

            <label style="color: gray; font-style: italic"
              >Se almacenará el formato seleccionado</label
            >
          </el-form-item>

          <div v-if="valueFormatoDecimales">
            <el-form-item label="Latitud" prop="latitud">
              <el-input v-model="form.latitud" @input="onPasteLat"></el-input>
            </el-form-item>

            <el-form-item label="Longitud" prop="longitud">
              <el-input v-model="form.longitud" @input="onPasteLong"></el-input>
            </el-form-item>
          </div>

          <div v-else>
            <h1>Latitud</h1>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="Grados" prop="gradosLat">
                  <el-input v-model.number="form.gradosLat">
                    <template slot="append">°</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="Minutos" prop="minutosLat">
                  <el-input v-model.number="form.minutosLat">
                    <template slot="append">'</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="Segundos" prop="segundosLat">
                  <el-input v-model.number="form.segundosLat">
                    <template slot="append">''</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="Direccion" prop="valueCardinalLat">
                  <el-select
                    v-model="form.valueCardinalLat"
                    placeholder="Seleccione punto cardinal"
                  >
                    <el-option
                      v-for="item in puntosCardinales"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <h1>Longitud</h1>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="Grados" prop="gradosLon">
                  <el-input v-model.number="form.gradosLon">
                    <template slot="append">°</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="Minutos" prop="minutosLon">
                  <el-input v-model.number="form.minutosLon">
                    <template slot="append">'</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="Segundos" prop="segundosLon">
                  <el-input v-model.number="form.segundosLon">
                    <template slot="append">''</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="Direccion" prop="valueCardinalLon">
                  <el-select
                    v-model="form.valueCardinalLon"
                    placeholder="Seleccione punto cardinal"
                  >
                    <el-option
                      v-for="item in puntosCardinales"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <el-form-item label="Paraje" prop="paraje">
          <el-select
            v-model="form.parajeID"
            class="m-2"
            placeholder="Seleccione un paraje de la lista"
            clearable
          >
            <el-option
              v-for="item in parajesSelect"
              :key="item.id"
              :label="item.nombre"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="Tipo de Beneficiario"
          prop="detalleTipoBeneficiario"
        >
          <el-select
            v-model="form.detalleTipoBeneficiario"
            class="m-2"
            placeholder="Seleccione un tipo de beneficiario de la lista"
            @change="cambio"
          >
            <el-option
              v-for="item in tiposBeneficiarioSelect"
              :key="item.id"
              :label="item.nombre"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="¿Tiene acompañante?" prop="tieneAcompaniante">
              <el-switch
                size="large"
                style="display: block; margin-top: 7px"
                v-model="form.tieneAcompaniante"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="SI"
                inactive-text="NO"
              >
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item>
          <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<style scoped>
.aiuda {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contenedor {
  background-color: #f5f7fa;
  padding: 20px;
  border-radius: 10px;
}
</style>
<script>
export default {
  name: "modificar-usuario",
  data() {
    let _self = this;
    return {
      form: {
        nombre: null,
        apellido: null,
        dni: null,
        cuitCuil: null,
        razonSocial: null,
        domicilio: null,
        email: null,
        fechaNac: null,
        telefono: null,
        departamentoID: null,
        localidadID: null,
        latitud: null,
        longitud: null,
        latitudDMS: null,
        longitudDMS: null,
        gradosLat: null,
        minutosLat: null,
        segundosLat: null,
        valueCardinalLat: null,
        gradosLon: null,
        minutosLon: null,
        segundosLon: null,
        valueCardinalLon: null,
        detalleTipoBeneficiario: null,
        parajeID: null,
        tieneAcompaniante: false,
      },
      datosActuales: {
        detalleBeneficiarioActual: null,
        departamentoActual: null,
        localidadActual: null,
      },
      cargando: false,
      persona: null,
      modificarLocalidad: false,

      urlTiposBeneficiarios: "/tipoBeneficiario/obtenerTodosSelect",
      urlLocalidad: "/localidad/obtenerTodosSelect",
      valueFormatoDecimales: true,
      valueVive: true,
      visible: false,
      edad: "",
      yaEligioFecha: false,

      urlPais: "/pais/obtenerTodosSelect",
      urlProvincia: "/provincia/obtenerTodosSelect",
      urlDepartamento: "/departamento/obtenerTodosSelect",
      urlParaje: "/paraje/obtenerTodosSelect",

      departamentosSelect: [],
      localidadesSelect: [],
      tiposBeneficiarioSelect: [],
      parajesSelect: [],
      cargandoLocalidades: false,
      puntosCardinales: [
        {
          value: "N",
          label: "Norte",
        },
        {
          value: "S",
          label: "Sur",
        },
        {
          value: "W",
          label: "Oeste",
        },
        {
          value: "E",
          label: "Este",
        },
      ],
      formRules: {
        gradosLat: [
          {
            required: true,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        latitud: [
          {
            required: true,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        longitud: [
          {
            required: true,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],

        minutosLat: [
          {
            required: true,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        segundosLat: [
          {
            required: true,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        valueCardinalLat: [
          {
            required: true,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        gradosLon: [
          {
            required: true,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        minutosLon: [
          {
            required: true,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        segundosLon: [
          {
            required: true,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],

        valueCardinalLon: [
          {
            required: true,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        dni: [
          {
            required: true,
            message: "Por favor introduzca el DNI.",
            trigger: "change",
          },
        ],

        domicilio: [
          {
            required: true,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        apellido: [
          {
            required: true,
            message: "Por favor introduzca el apellido.",
            trigger: "change",
          },
        ],
        nombre: [
          {
            required: true,
            message: "Por favor introduzca el nombre.",
            trigger: "change",
          },
        ],
        pais: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        cuit: [
          {
            required: true,
            message: "",
            trigger: "change",
          },
        ],
        provincia: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],

        paraje: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],

        telefono: [
          {
            required: true,
            message: "Por favor introduzca el teléfono.",
            trigger: "change",
          },
        ],
        fechaM: [
          {
            required: !this.valueVive,
            message: "Por favor introduzca el teléfono.",
            trigger: "change",
          },
        ],

        detalleTipoBeneficiario: [
          {
            required: true,
            message: "Por favor introduzca el tipo.",
            trigger: "change",
          },
        ],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  async created() {
    this.id = this.$route.params.id;
    await this.getDatos();
    console.log("primer form");
    console.log(this.form);
    this.getTiposDeBeneficiario();
    this.getDepartamentos();
    this.getParajes();
    this.yaEligioFecha = true;
    this.calcularEdad();
  },
  methods: {
    //convertir grados min seg y cardinalidad a lat y lng
    ConvertDMSToDD(degrees, minutes, seconds, direction) {
      var dd = degrees + minutes / 60 + seconds / (60 * 60);

      if (direction == "S" || direction == "W") {
        dd = dd * -1;
      } // Don't do anything for N or E
      return dd;
    },
    ParseDMS(input) {
      var parts = input.split(/[^\d\w]+/);

      var lat = ConvertDMSToDD(parts[0], parts[1], parts[2], parts[3]);
      var lng = ConvertDMSToDD(parts[4], parts[5], parts[6], parts[7]);
    },
    getPais(addrComponents) {
      for (var i = 0; i < addrComponents.length; i++) {
        if (addrComponents[i].types[0] == "country") {
          return addrComponents[i].short_name;
        }
        if (addrComponents[i].types.length == 2) {
          if (addrComponents[i].types[0] == "political") {
            return addrComponents[i].short_name;
          }
        }
      }
      return false;
    },

    async verificarCoordenadas() {
      let resultado;
      try {
        let geocoder = new google.maps.Geocoder(),
          latlng = new google.maps.LatLng(
            this.form.latitud,
            this.form.longitud
          );
        const result = await geocoder.geocode(
          { latLng: latlng },
          function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
              if (results[1]) {
                resultado = { status: "OK", result: results[1] };
              } else {
                resultado = { status: "INVALID", result: results };
              }
            } else {
              //failed

              resultado = { status: "INVALID", result: results[1] };
            }
          }
        );
      } catch (error) {
        this.$message({
          message:
            "Error al utilizar los servicios de google. Detalle:" +
            error.message,
          type: "error",
        });
        return false;
      }

      console.log("resultado", resultado);
      if (resultado.status != null && resultado.status == "OK") {
        const pais = this.getPais(resultado.result.address_components);
        if (pais != null && pais == "AR") {
          return true;
        } else {
          this.$message({
            message:
              "Se encuentra fuera de argentina, seleccione otras coordenadas.",
            type: "error",
          });
        }
        return false;
      } else {
        this.$message({
          message: "Coordenadas invalidas.",
          type: "error",
        });
        return false;
      }
    },
    cambio() {
      console.log("nuevo form", this.form.detalleTipoBeneficiario);
    },
    onPasteLat() {
      let latLong = this.form.latitud;
      latLong = latLong.split(", ");
      this.form.latitud = latLong[0];
      this.form.longitud = latLong[1];
      console.log(latLong);
    },
    onPasteLong() {
      let latLong = this.form.longitud;
      latLong = latLong.split(", ");
      this.form.latitud = latLong[0];
      this.form.longitud = latLong[1];
      console.log(latLong);
    },
    cambioDepartamento() {
      this.getLocalidades();
    },
    cambioLocalidad() {},
    async getTiposDeBeneficiario() {
      await this.$maca.api.get(this.urlTiposBeneficiarios).then((res) => {
        this.tiposBeneficiarioSelect = res.datos;
      });
    },
    async getDepartamentos() {
      let params = {
        provinciaID: 1,
        sinPaginar: 1,
      };
      await this.$maca.api.get(this.urlDepartamento, params).then((res) => {
        this.departamentosSelect = res.datos;
      });
    },
    async getLocalidades() {
      this.cargandoLocalidades = true;

      let params = {
        departamentoID: this.form.departamentoID,
        sinPaginar: 1,
      };
      await this.$maca.api
        .get("/localidad/obtenerTodos", params)
        .then((res) => {
          this.localidadesSelect = res.datos;
        });
      this.cargandoLocalidades = false;
    },
    async getParajes() {
      await this.$maca.api.get(this.urlParaje).then((res) => {
        this.parajesSelect = res.datos;
      });
    },
    calcularEdad() {
      let fecha1 = new Date(this.form.fechaNac.toString());
      let fecha2 = new Date();
      var edad = fecha2.getFullYear() - fecha1.getFullYear();
      var m = fecha2.getMonth() - fecha1.getMonth();

      if (m < 0 || (m === 0 && fecha2.getDate() < fecha1.getDate())) {
        edad--;
        if (fecha2.getFullYear() == fecha1.getFullYear()) {
          edad++;
        }
      }
      this.edad = edad;
      this.yaEligioFecha = true;
    },
    async getDatos() {
      this.cargando = true;
      let respuestaApi = await this.$maca.api.get(
        "/beneficiario/obtenerDatos",
        {
          id: this.id,
        }
      );

      this.cargando = false;

      if (respuestaApi != null) {
        this.form.nombre = respuestaApi.datos.detallePersona.nombre;
        this.form.apellido = respuestaApi.datos.detallePersona.apellido;
        this.form.dni = respuestaApi.datos.detallePersona.dni;
        this.form.cuitCuil = respuestaApi.datos.detallePersona.cuitCuil;
        this.form.razonSocial = respuestaApi.datos.detallePersona.razonSocial;
        this.form.domicilio = respuestaApi.datos.detallePersona.domicilio;
        this.form.email = respuestaApi.datos.detallePersona.email;
        this.form.fechaNac = respuestaApi.datos.detallePersona.fechaNac;
        this.form.telefono = respuestaApi.datos.detallePersona.telefono;
        this.datosActuales.departamentoActual =
          respuestaApi.datos.detallePersona.detalleDepartamento;
        this.datosActuales.localidadActual =
          respuestaApi.datos.detallePersona.detalleLocalidad;
        this.form.latitud = respuestaApi.datos.detallePersona.latitud;
        this.form.longitud = respuestaApi.datos.detallePersona.longitud;

        this.form.gradosLat =
          respuestaApi.datos.detallePersona.latitudDMS.grados;
        this.form.minutosLat =
          respuestaApi.datos.detallePersona.latitudDMS.minutos;
        this.form.segundosLat =
          respuestaApi.datos.detallePersona.latitudDMS.segundos;
        this.form.valueCardinalLat =
          respuestaApi.datos.detallePersona.latitudDMS.direccion;
        this.form.gradosLon =
          respuestaApi.datos.detallePersona.longitudDMS.grados;
        this.form.minutosLon =
          respuestaApi.datos.detallePersona.longitudDMS.minutos;
        this.form.segundosLon =
          respuestaApi.datos.detallePersona.longitudDMS.segundos;
        this.form.valueCardinalLon =
          respuestaApi.datos.detallePersona.longitudDMS.direccion;

        if (this.form.latitud != null && this.form.longitud != null)
          this.valueFormatoDecimales = true;
        else this.valueFormatoDecimales = false;

        if (respuestaApi.datos.tieneAcompaniante == 1) {
          this.form.tieneAcompaniante = true;
        } else {
          this.form.tieneAcompaniante = false;
        }
        this.form.parajeID = respuestaApi.datos.detallePersona.parajeID;
        this.form.detalleTipoBeneficiario =
          respuestaApi.datos.detalleTipoBeneficiario != null
            ? respuestaApi.datos.detalleTipoBeneficiario[0]
                .detalleTipoBeneficiario.id
            : null;
        this.datosActuales.detalleBeneficiarioActual =
          respuestaApi.datos.detalleTipoBeneficiario != null
            ? respuestaApi.datos.detalleTipoBeneficiario[0]
            : null;
      } else {
        this.cerrar();
      }
      console.log(this.form);
      console.log("form", this.form.detalleTipoBeneficiario);
      console.log("actual", this.datosActuales.detalleBeneficiarioActual);
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        let postOk = await this.postApi();
        afterSubmit();

        if (postOk) {
          this.$router.go(-1);
        }
      });
    },
    async postApi() {
      if (!this.valueFormatoDecimales) {
        this.form.latitud = this.ConvertDMSToDD(
          this.form.gradosLat,
          this.form.minutosLat,
          this.form.segundosLat,
          this.form.valueCardinalLat
        );
        this.form.longitud = this.ConvertDMSToDD(
          this.form.gradosLon,
          this.form.minutosLon,
          this.form.segundosLon,
          this.form.valueCardinalLon
        );
      }

      const result = await this.verificarCoordenadas();
      if (result == false) {
        return false;
      }
      let detalleTipoBeneficiario = [];
      let latitudDMS;
      let longitudDMS;
      let departamentoID;
      let localidadID;
      console.log("datos actuales");
      console.log(this.datosActuales);
      if (this.modificarLocalidad) {
        departamentoID = this.form.departamentoID;
        localidadID = this.form.localidadID;
      } else {
        departamentoID =
          this.datosActuales.departamentoActual != null
            ? this.datosActuales.departamentoActual.id
            : null;
        localidadID =
          this.datosActuales.localidadActual != null
            ? this.datosActuales.localidadActual.id
            : null;
      }
      detalleTipoBeneficiario.push(
        {
          id: this.datosActuales.detalleBeneficiarioActual.id,
          tipoBeneficiarioID:
            this.datosActuales.detalleBeneficiarioActual.detalleTipoBeneficiario
              .id,
          accion: "B",
        },
        {
          id: this.datosActuales.detalleBeneficiarioActual.id,
          tipoBeneficiarioID: this.form.detalleTipoBeneficiario,
          accion: "A",
        }
      );
      console.log(detalleTipoBeneficiario);

      // Hacer Post
      let params = { ...this.form };
      (params.id = this.id),
        (params.departamentoID =
          this.datosActuales.departamentoActual != null
            ? this.datosActuales.departamentoActual.id
            : null);

      if (!this.valueFormatoDecimales) {
        params.latitud = null;
        params.longitud = null;
        latitudDMS = {
          grados: this.form.gradosLat,
          minutos: this.form.minutosLat,
          segundos: this.form.segundosLat,
          direccion: this.form.valueCardinalLat,
        };

        longitudDMS = {
          grados: this.form.gradosLon,
          minutos: this.form.minutosLon,
          segundos: this.form.segundosLon,
          direccion: this.form.valueCardinalLon,
        };
        params.latitudDMS = JSON.stringify(latitudDMS);
        params.longitudDMS = JSON.stringify(longitudDMS);
      }

      params.localidadID = localidadID;
      params.detalleTipoBeneficiario = JSON.stringify(detalleTipoBeneficiario);

      if (this.form.parajeID == "" || this.form.parajeID == null) {
        delete params.parajeID;
      }
      if (this.form.localidadID == "" || this.form.localidadID == null) {
        delete params.localidadID;
      }
      if (this.form.departamentoID == "" || this.form.departamentoID == null) {
        delete params.departamentoID;
      }
      if (this.form.razonSocial == "" || this.form.razonSocial == null) {
        delete params.razonSocial;
      }

      if (this.form.cuitCuil == "" || this.form.cuitCuil == null) {
        delete params.cuitCuil;
      }
      if (this.form.fechaNac == "" || this.form.fechaNac == null) {
        delete params.fechaNac;
      }
      if (this.form.email == "" || this.form.email == null) {
        delete params.email;
      }
      console.log(params);
      let respuestaApi = await this.$maca.api.post(
        "/beneficiario/actualizar",
        params
      );

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Beneficiario actualizado con éxito!",
          type: "success",
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
    },
  },
  computed: {
    departamentoBeneficiario: {
      // getter
      get: function () {
        return this.datosActuales.departamentoActual
          ? this.datosActuales.departamentoActual.nombre
          : "";
      },
      // setter
      set: function (newValue) {
        this.datosActuales.departamentoActual = newValue;
      },
    },
    localidadBeneficiario: {
      // getter
      get: function () {
        return this.datosActuales.localidadActual
          ? this.datosActuales.localidadActual.nombre
          : "";
      },
      // setter
      set: function (newValue) {
        this.datosActuales.localidadActual = newValue;
      },
    },
  },
};
</script>
